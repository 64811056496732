import * as React from "react";
import { graphql } from "gatsby";
import { Box, Text, Flex } from "@chakra-ui/react";
import queryString from "query-string";
import SeoComponent from "../components/SeoComponent";
import CTAButton from "../components/CTAButton";

const isBrowser = typeof window !== "undefined";

function BuyNowPage(props) {
  const { data, location } = props;

  const [currentSearch, setCurrentSearch] = React.useState(null);
  const [pageMounted, setPageMounted] = React.useState(false);
  const iFrameContainerRef = React.useRef(null);
  const seoImg = data.sanityPage.seoImage?.image;

  React.useEffect(() => {
    setPageMounted(true);

    return () => {
      setPageMounted(false);
    };
  }, []);

  React.useEffect(() => {
    const qsObject = queryString.parse(location.search, {
      arrayFormat: "comma",
    });
    setCurrentSearch(qsObject["store_type"]);
  }, [props.location.search]);

  return (
    <>
      <SeoComponent
        title={data.sanityPage.seoTitle}
        description={data.sanityPage.seoDescription}
        imageUrl={seoImg?.asset?.url}
      />

      <Box
        w="100%"
        bg="rgb(241,240,250)"
        bgGradient="linear-gradient(180deg, rgba(241,240,250,1) 0%, rgba(255,255,255,1) 60%)"
        position="relative"
      >
        <Box
          display="none"
          w="100%"
          h="100%"
          position="absolute"
          top="0"
          style={{ zIndex: 0 }}
          border="1px"
        >
          {/* GRAY SVG */}
          <Box
            w="100%"
            maxH={{ base: "600px", md: "600px" }}
            position="absolute"
            top="0"
            left="0"
            style={{ zIndex: 0 }}
          >
            <svg
              viewBox="0 0 500 149"
              preserveAspectRatio="none"
              style={{ width: "100%", height: "100%", zIndex: 0 }}
            >
              <path
                fill="#F1F0FA"
                fillOpacity="1"
                d="M-3.10,110.05 C218.68,112.02 411.68,186.03 502.54,104.13 L500.00,0.00 L0.00,0.00 Z"
              ></path>
            </svg>
          </Box>
        </Box>

        {data.sanityPage.sections.map((section, indx) => {
          switch (section._type) {
            case "shopWanaOnline":
              return (
                <Flex
                  key={section._key}
                  w="100%"
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ zIndex: 1 }}
                >
                  {/* CONTENT */}
                  <Flex
                    w="100%"
                    direction="column"
                    alignItems="center"
                    mt={{ base: "114px", md: "114px" }}
                    style={{ zIndex: 1 }}
                  >
                    {/* TITLE */}
                    <Text
                      color="primary"
                      fontSize={{
                        base: "44px",
                        ms: "47px",
                        msx: "50px",
                        md: "53px",
                        mds: "56px",
                        lg: "59px",
                        lgs: "62px",
                        xl: "65px",
                      }}
                      fontWeight={{ base: "900", md: "900" }}
                      letterSpacing={{ base: "-0.5px", md: "-0.5px" }}
                      lineHeight={{ base: "1" }}
                      textAlign="center"
                      textTransform="uppercase"
                      mb={{ base: "26px", md: "26px" }}
                    >
                      {section.header || "shop wana online"}
                    </Text>

                    {/* SUBTITLE */}
                    <Text
                      w={{ base: "70vw", ms: "365px", msx: "370px" }}
                      color="primary"
                      fontSize={{ base: "12px", md: "15px" }}
                      fontWeight={{ base: "600", md: "600" }}
                      letterSpacing={{ base: "0px", md: "0px" }}
                      lineHeight={{ base: "24px", md: "30px" }}
                      textAlign="center"
                      mb={{ base: "12px", md: "29px" }}
                    >
                      Search a product below to find out where you can pre-order
                      for pick-up. Prices may vary by dispensary.
                      <br />
                      Don't need to pre-order?
                    </Text>

                    {/* FIND BTN */}
                    <CTAButton
                      href={section.findBtn.ctaRoute.route || "/"}
                      width={{ base: "270px", md: "316px" }}
                      height={{ base: "48px", lg: "48px" }}
                      fontSize={{ base: "13px", md: "13px" }}
                      fontWeight="900"
                      bgColor={
                        section.findBtn.bgColor
                          ? section.findBtn.bgColor.color
                          : "greenMantis"
                      }
                      color={
                        section.findBtn.textColor
                          ? section.findBtn.textColor.color
                          : "white"
                      }
                      _hover={{ bg: "#311D56", borderColor: "#311D56" }}
                      ctaText={section.findBtn.text}
                    />
                  </Flex>
                </Flex>
              );

            default:
              break;
          }
        })}

        {/* THIS PEACE OF CODE IS SET TO FORCE THE IFRAME TO UPDATE WHEN THE URL CHANGES AND WE ARE ON THE SAME PAGE */}

        {/* iHeartJane CONTAINER MEDICAL */}
        {pageMounted && currentSearch === "medical" && (
          <FrameWrapper refToUse={iFrameContainerRef} />
        )}

        {/* iHeartJane CONTAINER RECREATIONAL */}
        {pageMounted && currentSearch === "recreational" && (
          <FrameWrapper refToUse={iFrameContainerRef} />
        )}
      </Box>
    </>
  );
}

const FrameWrapper = ({ refToUse }) => {
  React.useEffect(() => {
    if (isBrowser) {
      const iFrameContainer = document.getElementById("jane-frame");
      const janeScript = document.getElementById("jane-frame-script");

      // console.log('iFrameContainer: ', iFrameContainer);
      let script = null;

      if (!janeScript) {
        script = document.createElement("script");
        script.src = "https://api.iheartjane.com/v1/brand_partners/29/embed.js";
        script.id = "jane-frame-script";
        script.async = true;

        iFrameContainer.appendChild(script);
      }
    }

    return () => {
      // console.log('FrameWrapper unmounted')
      // document.removeChild(iFrameContainer);
    };
  }, []);

  return (
    <Box
      ref={refToUse}
      id="jane-frame"
      w={{ base: "90vw", xl: "1332px" }}
      mt="52px"
      mx="auto"
      style={{ zIndex: 1 }}
    ></Box>
  );
};

export const query = graphql`
  query {
    sanityPage(slug: { current: { eq: "buy" } }) {
      _id
      slug {
        current
      }
      seoTitle
      seoDescription
      seoImage {
        image {
          asset {
            url
          }
        }
      }
      sections {
        ... on SanityShopWanaOnline {
          _key
          _type
          header
          findBtn {
            text
            textColor {
              color
            }
            bgColor {
              color
            }
            ctaRoute {
              route
            }
          }
        }
      }
    }
  }
`;

export default BuyNowPage;
